import { Social } from '../social';
import { InfoProps } from '../../../../types';
interface ContactsProps {
	social: InfoProps['social'];
	contact: InfoProps['contact'];
}
export const Contacts = ({ social, contact }: ContactsProps) => {
	const { name, moto } = contact;
	return (
		<div className="footer--item">
			<h3 className="footer__litter">Follow Me!</h3>
			<Social social={social} />
			<p className="footer__part__name coming-out">{name}</p>
			<p className="footer__part__moto">{moto}</p>
		</div>
	);
};
