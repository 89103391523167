// export const api = 'https://api.bulatkultash.com/tools?token=alkvmq34jgw3pepvsmflvm340_q34gsevms';
export const api = {
	skills: [
		{ id: 3, title: 'JS', info: '<p>Хорошие знания</p>', sort: 1 },
		{ id: 9, title: 'React', info: '<p>Reactjs</p>', sort: 2 },
		{
			id: 5,
			title: 'SASS, LESS, STYLUS',
			info: '<p>SASS, LESS, STYLUS</p>',
			sort: 3,
		},
		{
			id: 12,
			title: 'Node.js and NPM',
			info: '<p>Node.js and NPM</p>',
			sort: 4,
		},
		{ id: 10, title: 'Webpack', info: '<p>Webpack</p>', sort: 5 },
		{ id: 1, title: 'HTML5', info: '<p>Отличные знания</p>', sort: 6 },
		{ id: 2, title: 'CSS', info: '<p>Отличные знания</p>', sort: 6 },
		{
			id: 4,
			title: 'PHP and MySQL',
			info: '<p>Отличное понимание и применение MVC</p>',
			sort: 7,
		},
		{ id: 7, title: 'Gulp', info: '<p>Gulp for optimization</p>', sort: 10 },
		{ id: 8, title: 'Jquery', info: 'Регулярное использование', sort: 11 },
	],
	tools: [
		{ title: 'Working Machine', info: 'MacBook PRO', sort: 1 },
		{ title: 'IDE', info: 'PHPStorm, VSCode', sort: 2 },
		{ title: 'GIT', info: 'GitHub, GitLab', sort: 3 },
		{ title: 'Graphics', info: 'Adobe, Figma, Zeplin', sort: 4 },
		{ title: 'Servers', info: 'Node.js, Apache, Nginx, ', sort: 5 },
		{ title: 'Bug Tracking System', info: 'YouTrack, Jira', sort: 6 },
		{ title: 'Communication', info: 'Telegram, Skype, Slack', sort: 7 },
		{
			title: 'Browser',
			info: 'Google Chrome(and all others, of course)',
			sort: 9,
		},
		{ title: 'Languages', info: 'Русский, Български, English', sort: 11 },
	],
	contact: {
		email: 'dream@bulatkultash.com',
		whereami: 'Where am i now?',
		address: 'Moscow City, Russia',
		position: 'Senior JavaScript Developer',
		company: 'VTB BANK',
		companylink: 'https://vtb.ru',
		name: 'Bulat Kultash === Alex Stepanov',
		moto: 'I had a dream and my dream came true!',
	},
	social: {
		github: { name: 'github', link: 'https://github.com/bulatkultash' },
		vk: { name: 'vk', link: 'https://vk.com/dreaminitiator' },
		facebook: {
			name: 'facebook',
			link: 'https://www.facebook.com/bulatkultash',
		},
	},
	name: 'Bulat Kultash',
};
