import { useEffect, useState } from 'react';
import { api } from './info';
import { Tools } from './components/tools';
import { Skills } from './components/skills';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { Loading } from './components/loading';
import { InfoProps } from './types';

export const App = () => {
	const [info, setInfo] = useState<InfoProps | null>(null);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setInfo({
			skills: api.skills,
			tools: api.tools,
			contact: api.contact,
			social: api.social,
			name: api.name,
		});
		setLoading(true);
	}, []);

	let view;

	if (loading && info) {
		const { skills, tools, contact, social, name } = info;
		view = (
			<article>
				<Header name={name} />
				<main>
					<Skills skills={skills} />
					<Tools tools={tools} />
				</main>
				<Footer contact={contact} social={social} />
			</article>
		);
	} else if (!loading) {
		view = <Loading />;
	}

	return <div>{view}</div>;
};
export default App;
