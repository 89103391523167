import { Address } from './modules/address';
import { Status } from './modules/status';
import { Contacts } from './modules/contacts';
import { InfoProps } from '../../types';

interface FooterProps {
	social: InfoProps['social'];
	contact: InfoProps['contact'];
}

export const Footer = ({ social, contact }: FooterProps) => {
	return (
		<footer className="footer" id="">
			<Address contact={contact} />
			<Contacts contact={contact} social={social} />
			<Status contact={contact} />
		</footer>
	);
};
