import { InfoProps } from '../../../../types';

interface SocialItemProps {
	name: string;
	link: string;
}
const SocialItem = ({ name, link }: SocialItemProps) => {
	return (
		<li className="footer__social--item">
			<a className="footer__social--item__link" href={link} target="_blank">
				<img
					className="footer__social--item__link__icon"
					src={`/images/${name}${'.svg'}`}
					alt={name}
				/>
			</a>
		</li>
	);
};

interface SocialProps {
	social: InfoProps['social'];
}
export const Social = ({ social }: SocialProps) => {
	const socialArray = Object.values(social).map((item) => ({
		name: item.name,
		link: item.link,
	}));
	return (
		<ul className="footer__social list-inline" id="social">
			{socialArray.map((item, i) => (
				<SocialItem key={i} name={item.name} link={item.link} />
			))}
		</ul>
	);
};
