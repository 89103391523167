export const Loading = () => (
	<div className="loading">
		<svg
			width="200px"
			height="200px"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 100 100"
			preserveAspectRatio="xMidYMid"
			className="lds-ripple"
		>
			<circle cx="50" cy="50" r="41.4858" fill="none" stroke="#1875e5" strokeWidth="2">
				<animate
					attributeName="r"
					calcMode="spline"
					values="0;50"
					keyTimes="0;1"
					dur="3"
					keySplines="0 0.2 0.8 1"
					begin="-1.5s"
					repeatCount="indefinite"
				></animate>
				<animate
					attributeName="opacity"
					calcMode="spline"
					values="1;0"
					keyTimes="0;1"
					dur="3"
					keySplines="0.2 0 0.8 1"
					begin="-1.5s"
					repeatCount="indefinite"
				></animate>
			</circle>
			<circle cx="50" cy="50" r="16.8923" fill="none" stroke="#c5523f" strokeWidth="2">
				<animate
					attributeName="r"
					calcMode="spline"
					values="0;50"
					keyTimes="0;1"
					dur="3"
					keySplines="0 0.2 0.8 1"
					begin="0s"
					repeatCount="indefinite"
				></animate>
				<animate
					attributeName="opacity"
					calcMode="spline"
					values="1;0"
					keyTimes="0;1"
					dur="3"
					keySplines="0.2 0 0.8 1"
					begin="0s"
					repeatCount="indefinite"
				></animate>
			</circle>
		</svg>
	</div>
);
