const Headers = [
	{
		item: 'Web Developer',
	},
	{
		item: 'SEO',
	},
];

const header = Headers.map((el, i) => (
	<li key={i} className="header__skills--item">
		{el.item}
	</li>
));
interface HeaderProps {
	name: string;
}
export const Header = ({ name }: HeaderProps) => {
	return (
		<header className="header">
			<div className="header__text">
				<img className="header__image" src="/images/bulatkultash.jpg" alt={name} />
				<h1 className="header__name">{name}</h1>
				<ul className="header__skills">{header}</ul>
			</div>
		</header>
	);
};
