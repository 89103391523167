import { InfoProps } from '../../types';

interface SkillsProps {
	skills: InfoProps['skills'];
}
export const Skills = ({ skills }: SkillsProps) => {
	return (
		<div className="skills">
			<h2 className="skills__litter">Developer Skills</h2>
			<ul id="skills" className="skills__logos">
				{skills.map((el, i) => (
					<li key={i} className="skills__logos--item">
						<img
							className="skills__logos--item__image"
							src={`/images/skills/${el.id}.jpg`}
							alt={el.title}
						/>
					</li>
				))}
			</ul>
		</div>
	);
};
