import { InfoProps } from '../../../../types';

interface AddressProps {
	contact: InfoProps['contact'];
}
export const Address = ({ contact }: AddressProps) => {
	const { whereami, address, email } = contact;
	return (
		<div className="footer--item">
			<h3 className="footer__litter">{whereami}</h3>
			<p className="footer__address">{address}</p>
			<a className="footer__link" target="_blank" href={'mailto:' + email}>
				{email}
			</a>
		</div>
	);
};
