import { InfoProps } from '../../types';
interface ToolsProps {
	tools: InfoProps['tools'];
}
export const Tools = ({ tools }: ToolsProps) => {
	return (
		<div className="tools">
			<h2 className="tools__litter">Developer Tools</h2>
			<ul id="tools" className="tools__items">
				{tools.map((el, i) => (
					<li key={i} className="tools__items--item">
						<span className="tools__items--item__title">{el.title}:</span>
						<span className="tools__items--item__info">{el.info}</span>
					</li>
				))}
			</ul>
		</div>
	);
};
