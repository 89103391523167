import { InfoProps } from '../../../../types';

interface StatusProps {
	contact: InfoProps['contact'];
}

export const Status = ({ contact }: StatusProps) => {
	const { position, company, companyLink } = contact;
	return (
		<div className="footer--item">
			<h3 className="footer__litter">Job Status</h3>
			<p className="footer__jod">{position}</p>
			<a className="footer__link" href={companyLink as unknown as string} target="_blank">
				{company}
			</a>
		</div>
	);
};
